import React, { useState } from 'react';

function ScorecardForm() {
  const [formData, setFormData] = useState({
    player: '',
    course: '',
    weather: '',
    hole: '',
    par: '',
    score: '',
    shots: {
      drive: '',
      approach: '',
      putting: ''
    },
    course_condition: ''
  });

  // Update form state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleShotChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      shots: {
        ...formData.shots,
        [name]: value
      }
    });
  };

  // Submit form data to the API
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://005if9khtb.execute-api.eu-west-1.amazonaws.com/dev/scorecard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      console.log(result);
      alert('Scorecard submitted successfully!');
    } catch (error) {
      console.error('Error submitting scorecard:', error);
      alert('Error submitting scorecard.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Submit Golf Scorecard</h2>
      
      <label>
        Player:
        <input type="text" name="player" value={formData.player} onChange={handleChange} required />
      </label>
      <label>
        Course:
        <input type="text" name="course" value={formData.course} onChange={handleChange} required />
      </label>
      <label>
        Weather:
        <input type="text" name="weather" value={formData.weather} onChange={handleChange} required />
      </label>
      <label>
        Hole:
        <input type="number" name="hole" value={formData.hole} onChange={handleChange} required />
      </label>
      <label>
        Par:
        <input type="number" name="par" value={formData.par} onChange={handleChange} required />
      </label>
      <label>
        Score:
        <input type="number" name="score" value={formData.score} onChange={handleChange} required />
      </label>

      <h3>Shots</h3>
      <label>
        Drive:
        <input type="text" name="drive" value={formData.shots.drive} onChange={handleShotChange} required />
      </label>
      <label>
        Approach:
        <input type="text" name="approach" value={formData.shots.approach} onChange={handleShotChange} required />
      </label>
      <label>
        Putting:
        <input type="text" name="putting" value={formData.shots.putting} onChange={handleShotChange} required />
      </label>

      <label>
        Course Condition:
        <input type="text" name="course_condition" value={formData.course_condition} onChange={handleChange} required />
      </label>

      <button type="submit">Submit</button>
    </form>
  );
}

export default ScorecardForm;

